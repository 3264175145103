import { styled } from '~/utils/styling';

import { Box, createSpacingVariant } from './Box';

const Grid = styled(Box, {
  display: 'grid',

  variants: {
    gap: createSpacingVariant('gridGap'),
    columns: {
      1: { gridTemplateColumns: '1fr' },
      2: { gridTemplateColumns: '1fr 1fr' },
      3: { gridTemplateColumns: '1fr 1fr 1fr' },
      4: { gridTemplateColumns: '1fr 1fr 1fr 1fr' }
    },
    alignItems: {
      start: { alignItems: 'start' },
      center: { alignItems: 'center' },
      end: { alignItems: 'end' }
    }
  }
});

const Column = styled(Box, {
  display: 'grid',
  variants: {
    gap: createSpacingVariant('gridGap'),
    span: {
      1: { gridColumn: 'auto / span 1' },
      2: { gridColumn: 'auto / span 2' },
      3: { gridColumn: 'auto / span 3' },
      4: { gridColumn: 'auto / span 4' }
    }
  }
});

export { Grid, Column };
