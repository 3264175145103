import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareLinkedinModal = lazy(() => import('~/features/share/modals/ShareLinkedinModal'));

function useShareLinkedin() {
  const { open } = useModal(ShareLinkedinModal);
  return open;
}

export { useShareLinkedin };
