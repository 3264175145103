import { useMemo } from 'react';

import { useBulkRemoveMembers } from './useBulkRemoveMembers';
import { useBulkUpdateRole } from './useBulkUpdateRole';
import { useInviteMembers } from './useInviteMembers';
import { useInviteRecruiters } from './useInviteRecruiters';
import { useInviteUsers } from './useInviteUsers';
import { useRemoveMember } from './useRemoveMember';
import { useResendTeamMemberInvite } from './useResendTeamMemberInvite';
import { useSSOSignupLink } from './useSSOSignupLink';
import { useTeamSignupLink } from './useTeamSignupLink';
import { useUpdateRole } from './useUpdateRole';

function useTeamActions() {
  const updateRole = useUpdateRole();
  const inviteUsers = useInviteUsers();
  const inviteMembers = useInviteMembers();
  const inviteRecruiters = useInviteRecruiters();
  const removeMember = useRemoveMember();
  const teamSignupLink = useTeamSignupLink();
  const ssoSignupLink = useSSOSignupLink();
  const bulkUpdateRole = useBulkUpdateRole();
  const bulkRemoveMembers = useBulkRemoveMembers();
  const resendTeamMemberInvite = useResendTeamMemberInvite();

  return useMemo(
    () => ({
      updateRole,
      inviteMembers,
      inviteRecruiters,
      inviteUsers,
      removeMember,
      teamSignupLink,
      ssoSignupLink,
      bulkUpdateRole,
      bulkRemoveMembers,
      resendTeamMemberInvite
    }),
    [
      updateRole,
      inviteMembers,
      inviteRecruiters,
      inviteUsers,
      removeMember,
      teamSignupLink,
      ssoSignupLink,
      bulkUpdateRole,
      bulkRemoveMembers,
      resendTeamMemberInvite
    ]
  );
}

export { useTeamActions };
