import { lazy } from 'react';

import { LoadingMessage } from '~/components/loading-spinner/LoadingMessage';
import { useRoutedModal } from '~/components/modal';
import { useDashboardRedirect } from '~/features/auth/utils/useDashboardRedirect';
import { Head } from '~/features/global/components/Head';
import { DashboardMasthead } from '~/features/global/components/masthead/DashboardMasthead';
import { useRoutedActions } from '~/features/global/utils/useRoutedActions';
import { useRoutedModals } from '~/features/global/utils/useRoutedModals';
import { useAuth } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps, PropsWithChildren } from 'react';

const JoinWorkspaceModal = lazy(() => import('~/features/workspaces/modals/JoinWorkspaceModal'));

const WrapLoading = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '10rem'
});

type DashboardLayoutProps = PropsWithChildren<{
  loading?: boolean;
  title?: ComponentProps<typeof Head>['title'];
}>;

export function DashboardLayout({ children, title, loading }: DashboardLayoutProps) {
  const isRedirecting = useDashboardRedirect();
  const auth = useAuth();
  const router = useRouter();

  useRoutedModals();
  useRoutedActions();

  // NOTE: we only want to use this modal when the user is authenticated (= seeing the dashboard layout),
  // otherwise we show a different flow as part of the auth pages, which is why this modal is not part of
  // the geneeral `useRoutedModals` hook
  useRoutedModal(JoinWorkspaceModal, { queryParam: 'join', skip: isRedirecting });

  if (isRedirecting) {
    return null;
  }

  if (auth?.account?.role === 'RESTRICTED') {
    router.replace('/recruiter-portal/library');
    return null;
  }

  return (
    <>
      {title && <Head title={title} />}

      <DashboardMasthead />

      {loading ? (
        <WrapLoading>
          <LoadingMessage message="Loading data..." />
        </WrapLoading>
      ) : (
        children
      )}
    </>
  );
}
