/* eslint-disable max-lines */
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { styled } from '~/utils/styling';
import { useTheme } from '~/utils/styling/useTheme';

import type { Placement } from '@popperjs/core/index';
import type { ReactNode, Ref, Dispatch, SetStateAction, ComponentPropsWithoutRef, ComponentType } from 'react';

const OVERFLOW_PADDING = 18;

const Container = styled('div', {
  // Required for safari to properly size the container
  display: 'flex',
  zIndex: 10,
  background: '$light-1000',
  color: '$$dark-1000',
  filter: 'drop-shadow($shadows$medium)',
  borderRadius: '$tiny',
  overflowY: 'auto',
  maxWidth: `calc(100svw - ${OVERFLOW_PADDING}px * 2)`,
  // Required to display the filter in safari
  willChange: 'transform',

  // React popper renders a lonely div as the child of the container, which messes with the zIndex
  // layers since the arrow is rendered after it in the DOM
  '&, & > div:first-child': {
    position: 'relative',
    zIndex: 10
  }
});

const Inner = styled('div', {
  width: '100%'
});

const Arrow = styled('div', {
  visibility: 'hidden',
  zIndex: 1,

  '&:before': {
    // visibility: 'visible',
    content: '" "',
    transform: 'rotate(45deg)'
  },

  '&, &:before': {
    position: 'absolute',
    zIndex: 1,
    width: '.5rem',
    height: '.5rem',
    background: '$light-1000'
  },

  '[data-popper-placement^="top"] > &': {
    bottom: '-.25rem'
  },

  '[data-popper-placement^="bottom"] > &': {
    top: '-.25rem'
  },

  '[data-popper-placement^="left"] > &': {
    right: '-.25rem'
  },

  '[data-popper-placement^="right"] > &': {
    left: '-.25rem'
  }
});

type ChildrenProps = {
  ref: Ref<any>;
  onMouseDown: (e: any) => void;
  onClick: (e: any) => void;
  onKeyDown: (e: any) => void;
  id: string;
  'aria-haspopup': true;
  'aria-controls': string;
  'aria-expanded': boolean;
  _: {
    element?: HTMLElement | null;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    update: (() => any) | null;
  };
};

type ContentPassthroughProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  element?: HTMLElement | null;
  update: (() => any) | null;
};

type PopoutProps<ContentComponent extends ComponentType<ContentPassthroughProps>> = {
  contentProps?: Omit<ComponentPropsWithoutRef<ContentComponent>, keyof ContentPassthroughProps>;
  Content: ContentComponent;
  placement?: Placement;
  portalTarget?: HTMLElement;
  showArrow?: boolean;
  ignoreOutsideFocus?: boolean;
  children?: (props: ChildrenProps) => ReactNode;
  virtualElement?: { getBoundingClientRect: () => DOMRect };
  visible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
};

function Popout<ContentComponent extends ComponentType<ContentPassthroughProps>>({
  contentProps,
  Content,
  children,
  virtualElement,
  placement = 'bottom',
  portalTarget,
  showArrow = false,
  ignoreOutsideFocus = false,
  visible: visibleProp,
  setVisible: setVisibleProp,
  ...props
}: PopoutProps<ContentComponent>) {
  const id = useId();
  const [_visible, _setVisible] = useState(false);

  const visible = visibleProp ?? _visible;
  const setVisible = setVisibleProp ?? _setVisible;

  // Initialise popper
  const [element, setElement] = useState<HTMLElement>();
  const [popper, setPopper] = useState<HTMLElement>();
  const [arrow, setArrow] = useState<HTMLElement>();
  const { styles, attributes, update } = usePopper(virtualElement || element, popper, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: { element: showArrow ? arrow : undefined }
      },
      {
        name: 'offset',
        options: { offset: [0, 8] }
      },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
          altAxis: true,
          padding: OVERFLOW_PADDING
        }
      }
    ]
  });

  const ContentComponent = Content as ComponentType<ContentPassthroughProps>;
  const { currentTheme } = useTheme();

  // Get portal target
  const effectivePortalTarget =
    typeof window !== 'undefined'
      ? portalTarget || element?.closest('[data-tether-target]') || window.document.body
      : undefined;

  // NOTE: when clicking on an element, it will also trigger the `focus` event
  // which causes issues when toggling the visible state. To work around that we
  // also listen to the `mousedown`, which triggers before the `focus`, to
  // temporarily disable the `focus` handler
  const ignoreFocus = useRef(false);
  const handleMouseDown = useCallback(() => {
    ignoreFocus.current = true;
  }, []);

  const handleToggle = useCallback(() => {
    setVisible((state) => !state);
  }, [setVisible]);

  const handleShow = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleHide = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // Safari does not focus elements onClick so we have to manually do it here to ensure
      // the same behaviour across browsers
      element?.focus();
      handleToggle();
    },
    [element, handleToggle]
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      switch (e.key) {
        case 'Escape':
          handleHide();
          break;
        case 'Space':
          handleToggle();
          break;
        default:
          break;
      }
    },
    [handleHide, handleToggle]
  );

  useEffect(() => {
    if (!visible) {
      return;
    }

    function handleOutsideClick(e: any) {
      // HACK: we're checking if the element is in the body, to cover e.g. select tags that get removed on click. Ideally
      // the `stopPropagation` would make sure they don't even ever trigger this, but that doesn't seem to work right now,
      // so this is the temporary fix/workaround
      if (
        !popper?.contains?.(e.target) &&
        !element?.contains?.(e.target) &&
        window.document.body?.contains?.(e.target)
      ) {
        handleHide();
      }
    }
    window.document.body.addEventListener('click', handleOutsideClick);
    return () => window.document.body.removeEventListener('click', handleOutsideClick);
  }, [visible, popper, element, handleHide]);

  useEffect(() => {
    function handleOutsideFocus(e: any) {
      if (!popper?.contains?.(e.target) && !element?.contains?.(e.target)) {
        if (e.target === effectivePortalTarget) {
          // HACK: for whatever reason this fixes an issue where e.g. the color picker popup would
          // close when used within modals because the modal (which is the portal target) receives
          // focus when the user picks a color :/
          return;
        }
        return handleHide();
      }
      if (ignoreFocus.current) {
        ignoreFocus.current = false;
        return;
      }
      if (ignoreOutsideFocus) {
        return;
      }
      handleShow();
    }
    window.document.body.addEventListener('focus', handleOutsideFocus, true);
    return () => window.document.body.removeEventListener('focus', handleOutsideFocus, true);
  }, [popper, element, handleShow, handleHide, visible, effectivePortalTarget, ignoreOutsideFocus]);

  return (
    <>
      {children &&
        children({
          ref: setElement as Ref<any>,
          onMouseDown: handleMouseDown,
          onClick: handleClick,
          onKeyDown: handleKeyDown,
          id: `popup-button-${id}`,
          'aria-haspopup': true,
          'aria-controls': `popup-content-${id}`,
          'aria-expanded': visible,
          _: {
            element,
            visible,
            setVisible,
            update
          }
        })}

      {effectivePortalTarget &&
        visible &&
        createPortal(
          <Container
            ref={setPopper as Ref<HTMLDivElement>}
            id={`popup-content-${id}`}
            aria-labelledby={`popup-button-${id}`}
            style={styles.popper}
            {...attributes.popper}
            data-popper
            className={currentTheme}
            {...props}
          >
            <Inner>
              <ContentComponent
                {...contentProps}
                visible={visible}
                setVisible={setVisible}
                element={element}
                update={update}
              />
            </Inner>
            {showArrow && <Arrow data-popper-arrow ref={setArrow as Ref<HTMLDivElement>} style={styles.arrow} />}
          </Container>,
          effectivePortalTarget
        )}
    </>
  );
}

export { Popout };
export type { ContentPassthroughProps };
