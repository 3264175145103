// Polyfilling globalThis manually here, because polyfill.io doesn't seem to cover all
// edge versions that need the polyfill, which will make stitches break meaning it's pretty
// critical :|
import 'core-js/features/global-this';

import { ApolloProvider } from '@apollo/client';
import App from 'next/app';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Workbox } from 'workbox-window';

import { ErrorBoundary } from '~/components/error-boundary';
import { LoadingMessage } from '~/components/loading-spinner';
import { ModalProvider } from '~/components/modal';
import { NotificationProvider } from '~/components/notification';
import { useAuthBroadcast } from '~/features/auth/utils/useAuthBroadcast';
import { CustomThumbnailExpectVouchUpdateProvider } from '~/features/editor/utils/CustomThumbnailExpectVouchUpdateContext';
import { ConstructionBanner } from '~/features/global/components/ConstructionBanner';
import { Head } from '~/features/global/components/Head';
import { AuthLayout } from '~/layouts/AuthLayout';
import { ErrorLayout } from '~/layouts/ErrorLayout';
import { ExtensionLayout } from '~/layouts/ExtensionLayout';
import { DashboardLayout } from '~/layouts/dashboard/DashboardLayout';
import { AnalyticsScripts, reportWebVitals } from '~/utils/analytics';
import { createClient } from '~/utils/apollo';
import { AuthProvider, loadAuth, useAuth } from '~/utils/auth';
import { queryClient, mutateClient } from '~/utils/graphql';
import { I18nProvider } from '~/utils/i18n';
import { getServerTimingHeader, timerEnd, timerStart } from '~/utils/performance';
import { useRouter } from '~/utils/routing/useRouter';
import { useSentry } from '~/utils/sentry';
import { initGlobalStyles } from '~/utils/styling';
import { ThemeProvider } from '~/utils/styling/useTheme';

import type { AppProps } from 'next/app';

if (typeof window !== 'undefined') {
  // Expose some internal methods, currently used in E2E tests, which we should
  // revert eventually
  window.__VOUCH__ = {
    queryClient,
    mutateClient,
    adminUrl: process.env.NEXT_PUBLIC_ADMIN_URL,
    appUrl: process.env.NEXT_PUBLIC_APP_URL
  };
}

type VouchAppProps = AppProps & {
  auth: any;
};

type InnerContentProps = {
  Component: VouchAppProps['Component'];
  pageProps: VouchAppProps['pageProps'];
  resetClient?: () => any;
};

function InnerContent({ Component, resetClient, pageProps }: InnerContentProps) {
  // HACK: the apollo client doesn't properly reset between signing out and signing in, despite
  // our explicit efforts in `signOut.ts`, so as a hacky workaround we create a new client whenever
  // the auth entity id changes
  const auth = useAuth();
  useEffect(
    () => resetClient?.(),
    // eslint-disable-next-line
    [auth?.entity?.id]
  );

  useSentry();
  useAuthBroadcast();

  const router = useRouter();

  if (auth?.status === 'signingOut') {
    return (
      <AuthLayout
        title="Sign out"
        heading="Sign out"
        content={<LoadingMessage message="Signing out of your account..." />}
      />
    );
  }

  if (auth?.status === 'switchingEntity') {
    const Layout = router.pathname.startsWith('/dashboard/extension') ? ExtensionLayout : DashboardLayout;
    return (
      <Layout>
        <LoadingMessage message="Switching spaces..." css={{ margin: 'auto' }} />
      </Layout>
    );
  }

  return <Component {...pageProps} />;
}

function VouchApp({ Component, pageProps, auth: authProp }: VouchAppProps) {
  initGlobalStyles();

  // HACK: create resettable apollo client, see above for details
  const [key, setKey] = useState(0);
  const resetClient = useCallback(() => setKey((key) => key + 1), []);
  const client = useMemo(
    () => createClient(),
    // eslint-disable-next-line
    [key]
  );

  return (
    <>
      {/* Any analytics scrips we want to add to the head, using the nextjs `Script` components */}
      {/* See https://nextjs.org/docs/basic-features/script */}
      <AnalyticsScripts />

      <Head />

      <NuqsAdapter>
        <ThemeProvider>
          <NotificationProvider>
            <ApolloProvider client={client}>
              <AuthProvider data={authProp}>
                <I18nProvider>
                  <CustomThumbnailExpectVouchUpdateProvider>
                    <ModalProvider>
                      <ErrorBoundary fallback={ErrorLayout}>
                        {!!process.env.NEXT_PUBLIC_SHOW_ENVIRONMENT_WARNING && (
                          <ConstructionBanner env={process.env.NEXT_PUBLIC_ENV || 'local'} />
                        )}
                        <InnerContent Component={Component} pageProps={pageProps} resetClient={resetClient} />
                      </ErrorBoundary>
                    </ModalProvider>
                  </CustomThumbnailExpectVouchUpdateProvider>
                </I18nProvider>
              </AuthProvider>
            </ApolloProvider>
          </NotificationProvider>
        </ThemeProvider>
      </NuqsAdapter>
    </>
  );
}

/**
 * This `getInitialProps` will authenticate the user (and eventually fetch some basic account and
 * entity information) on the server side on initial load, and inject it into the component props
 * passed on to the app
 */
VouchApp.getInitialProps = async function (context: any) {
  timerStart('request', { desc: 'Next: getInitialProps' });

  timerStart('nextGetInitialProps', { desc: 'Next: getInitialProps - Get Initial Props' });
  const appProps = await App.getInitialProps(context);
  timerEnd('nextGetInitialProps');

  const isServer = typeof window === 'undefined';
  const method = context.ctx?.req?.method;
  const url = context.ctx?.req?.url;

  // Apparently `getInitialProps` also gets called on the client on every navigation, so we
  // prevent any auth API calls from being made here. We're also skipping the auth API part
  // for any requests that are not `GET` requests, because they won't have the cookies we
  // need for auth anyway
  const skipAuth = !isServer || method !== 'GET' || url?.startsWith?.('/_next/data/');
  if (skipAuth) {
    timerEnd('request');
    context.ctx?.res?.setHeader?.('Server-Timing', getServerTimingHeader());
    return appProps;
  }

  try {
    timerStart('loadAuth', { desc: 'Next: getInitialProps - Load Auth' });
    const auth = await loadAuth({ context: context.ctx });
    timerEnd('loadAuth');

    timerEnd('request');
    context.ctx?.res?.setHeader?.('Server-Timing', getServerTimingHeader());
    return { ...appProps, auth };
  } catch (e) {
    if (__DEV__) {
      console.error('getInitialProps failed', e);
    }
    return appProps;
  }
};

// Register service workers
if (typeof window !== 'undefined' && 'serviceWorker' in window.navigator) {
  const sw = new Workbox('/sw.js');
  sw.register();
}

export { reportWebVitals };
export default VouchApp;
