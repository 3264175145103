import { Icon } from '~/components/icon';
import { Tooltip } from '~/components/tooltip';
import { PlanSticker } from '~/features/billing/components/PlanSticker';
import { styled, text } from '~/utils/styling';

import type { ReactNode, ComponentProps } from 'react';

const LabelContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$wee'
});

const WrapLabel = styled('div', {
  display: 'flex',
  alignItems: 'flex-end'
});

const Label = styled('label', {
  ...text({ size: 'medium' }),
  fontWeight: '$bold',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$wee',

  variants: {
    inline: {
      true: {
        marginTop: 'calc($sizes$medium / 2)',
        transform: 'translateY(-50%)',
        whiteSpace: 'nowrap',
        minWidth: '8rem'
      }
    }
  }
});

const HelpIcon = styled('div', {
  display: 'flex',
  color: '$dark-600'
});

const Description = styled('span', {
  fontSize: '$small',
  color: '$dark-600',

  variants: {
    inline: {
      true: {
        marginTop: '-.5rem'
      }
    }
  }
});

type FieldLabelProps = {
  label?: ReactNode;
  labelAction?: ReactNode;
  description?: ReactNode;
  inline?: ComponentProps<typeof Label>['inline'];
  showOptional?: boolean;
  helpTooltip?: ComponentProps<typeof Tooltip>['content'];
  required?: boolean;
  plan?: ComponentProps<typeof PlanSticker>['type'];
  name?: string;
};

function FieldLabel({
  label,
  description,
  inline,
  showOptional,
  helpTooltip,
  plan,
  required,
  labelAction,
  name
}: FieldLabelProps) {
  if (!label && !description) {
    return null;
  }

  return (
    <LabelContainer>
      <WrapLabel>
        {label && (
          <Label htmlFor={name} inline={inline}>
            <span>
              {label}
              {!required && showOptional && ' (optional)'}
            </span>

            {helpTooltip && (
              <Tooltip content={helpTooltip}>
                {(props) => (
                  <HelpIcon {...props}>
                    <Icon name="help" />
                  </HelpIcon>
                )}
              </Tooltip>
            )}

            {plan && <PlanSticker type={plan} />}
          </Label>
        )}
        {labelAction}
      </WrapLabel>
      {description && <Description inline={inline}>{description}</Description>}
    </LabelContainer>
  );
}

export { FieldLabel };
