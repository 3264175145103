import { forwardRef } from 'react';

import type { SVGProps } from 'react';

import { icons } from './svgs/index';

type IconProps = Omit<SVGProps<SVGSVGElement>, 'name'> & {
  name: keyof typeof icons;
};

const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon({ name, ...props }, ref) {
  const Component = icons[name];
  // Icons can be described with the img role allowing screen
  // readers to only read out the top svg tag and not any
  // of the nested elements
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role#svg_and_roleimg
  return <Component {...props} ref={ref} role="img" />;
});

export { Icon };
export type { IconProps };
