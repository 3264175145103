import { Link } from '~/utils/routing/Link';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';
import type { Item } from '~/components/action-menu';

const Container = styled('div', {});

const Menu = styled('menu', {
  margin: 0,
  padding: 0,
  height: '100%',

  '& ul': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '$wee',
    margin: 0,
    padding: 0,
    listStyle: 'none'
  }
});

const MenuItem = styled(Link, {
  textDecoration: 'none',
  color: 'inherit',
  fontWeight: '$bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  border: '0 none',
  cursor: 'pointer',
  padding: '$tiny $small',
  borderRadius: '999rem',

  '&:hover, &:focus': {
    background: '$purple-100',
    color: '$purple-900'
  },

  variants: {
    active: {
      true: {
        '&, &:hover, &:focus': {
          background: '$purple-500',
          color: 'white'
        }
      }
    }
  }
});

type DesktopMenuProps = ComponentProps<typeof Container> & {
  items: Item[];
};

function DesktopMenuRecruiter({ items, ...props }: DesktopMenuProps) {
  const { pathname } = useRouter();

  return (
    <Container {...props}>
      <Menu>
        <ul>
          {items.map((item) => {
            if ('isCustom' in item) {
              return null;
            }

            if ('href' in item && item.href) {
              return (
                <li key={item.label as string}>
                  <MenuItem href={item.href} active={item.selected || pathname.startsWith(item.href.toString())}>
                    {item.label}
                  </MenuItem>
                </li>
              );
            }

            return (
              <li key={item.label as string}>
                <MenuItem as="button" onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              </li>
            );
          })}
        </ul>
      </Menu>
    </Container>
  );
}

export { DesktopMenuRecruiter };
