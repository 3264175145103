/* eslint-disable max-lines */
import { forwardRef, useCallback, useState } from 'react';
import mergeRefs from 'react-merge-refs';

import { Icon } from '~/components/icon';
import { LoadingSpinner } from '~/components/loading-spinner';
import { Tooltip } from '~/components/tooltip';
import { useAnalyticsEvent } from '~/utils/analytics';
import { Link } from '~/utils/routing/Link';
import { styled } from '~/utils/styling';

import type { ComponentProps, MouseEvent, ForwardedRef } from 'react';

const Container = styled('button', {
  $$buttonLabelSpacing: '$space$wee',
  $$buttonColor: 'inherit',
  $$buttonHoverColor: 'inherit',
  $$buttonIconColor: 'inherit',

  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$small',
  fontWeight: '$bold',
  textDecoration: 'none',
  border: '0 none',
  transition: 'transform .2s',
  whiteSpace: 'nowrap',
  background: '$$buttonBackground',
  color: '$$buttonColor',
  boxShadow: '$$buttonBoxShadow',

  '& svg': {
    color: '$$buttonIconColor',
    height: '1.2em',
    width: 'auto',
    display: 'flex',
    flexShrink: 0
  },

  '&:hover, &:focus': {
    background: '$$buttonHoverBackground',
    color: '$$buttonHoverColor',

    '& svg': {
      color: '$$buttonHoverColor'
    }
  },

  '&:active': {
    transform: 'translateY(.0625rem)'
  },

  '&[data-disabled=true]': {
    '&&': {
      cursor: 'default',
      background: '$dark-80',
      color: '$dark-200',

      '&:active': {
        transform: 'none'
      },

      '& svg': {
        color: '$dark-200'
      }
    }
  },

  variants: {
    color: {
      custom: {},
      brand: {
        $$buttonBackground: '$colors$s-brand-800',
        $$buttonColor: '$colors$s-brand-50',
        $$buttonHoverBackground: '$colors$s-brand-900',
        $$buttonHoverColor: '$colors$s-brand-50'
      },
      'brand-light': {
        $$buttonBackground: '$colors$s-brand-100',
        $$buttonColor: '$colors$s-brand-800',
        $$buttonHoverBackground: '$colors$s-brand-200',
        $$buttonHoverColor: '$colors$s-brand-900'
      },
      blue: {
        $$buttonBackground: '$colors$blue-500',
        $$buttonColor: '$colors$blue-50',
        $$buttonHoverBackground: '$colors$blue-600',
        $$buttonHoverColor: '$colors$blue-50'
      },
      purple: {
        $$buttonBackground: '$colors$purple-700',
        $$buttonColor: '$colors$light-1000',
        $$buttonHoverBackground: '$colors$purple-900',
        $$buttonHoverColor: '$colors$light-1000'
      },
      'purple-light': {
        $$buttonBackground: '$colors$purple-100',
        $$buttonColor: '$colors$purple-700',
        $$buttonHoverBackground: '$colors$purple-200',
        $$buttonHoverColor: '$colors$purple-700'
      },
      'white-purple': {
        $$buttonBackground: '$colors$light-1000',
        $$buttonColor: '$colors$purple-700',
        $$buttonHoverBackground: '$colors$purple-500',
        $$buttonHoverColor: '$colors$light-1000',
        $$buttonIconColor: '$colors$purple-500'
      },
      dark: {
        $$buttonBackground: '$colors$dark-800',
        $$buttonColor: '$colors$light-1000',
        $$buttonHoverBackground: '$colors$dark-1000',
        $$buttonHoverColor: '$colors$light-1000'
      },
      light: {
        $$buttonBackground: '$colors$light-1000',
        $$buttonColor: '$colors$dark-1000',
        $$buttonHoverBackground: '$colors$s-brand-100',
        $$buttonHoverColor: '$colors$s-brand-900'
      },
      grey: {
        $$buttonBackground: '$colors$dark-80',
        $$buttonColor: '$colors$dark-1000',
        $$buttonHoverBackground: '$colors$dark-200',
        $$buttonHoverColor: '$colors$dark-1000'
      },
      transparent: {
        $$buttonBackground: 'transparent',
        $$buttonColor: '$colors$s-brand-800',
        $$buttonHoverBackground: '$colors$light-1000',
        $$buttonHoverColor: '$colors$s-brand-900'
      },
      danger: {
        $$buttonBackground: '$colors$s-danger-500',
        $$buttonColor: '$colors$s-danger-50',
        $$buttonHoverBackground: '$colors$s-danger-600',
        $$buttonHoverColor: '$colors$s-danger-50'
      }
    },
    // TODO: see if we can deprecate this? from memory this was only used on an earlier version of the
    // home dashboard
    iconColor: {
      brand: {
        $$buttonIconColor: '$colors$s-brand-800'
      },
      teal: {
        $$buttonIconColor: '$colors$teal-600'
      },
      purple: {
        $$buttonIconColor: '$colors$purple-700'
      },
      'purple-light': {
        $$buttonIconColor: '$colors$purple-100'
      },
      'white-purple': {
        $$buttonIconColor: '$colors$purple-500'
      },
      'blue-light': {
        $$buttonIconColor: '$colors$blue-500'
      },
      blue: {
        $$buttonIconColor: '$colors$blue-600'
      },
      grey: {
        $$buttonIconColor: '$colors$dark-600'
      }
    },
    size: {
      wee: {
        height: '$tiny',
        padding: '0',
        fontSize: '$small',

        '& svg': {
          height: '1.3em'
        }
      },
      small: {
        height: '$tiny',
        padding: '0 $tiny',
        fontSize: '$small',

        '& svg': {
          height: '1.3em'
        }
      },
      medium: {
        height: '$small',
        padding: '0 $small',
        fontSize: '$medium'
      },
      large: {
        $$buttonLabelSpacing: '$space$tiny',

        height: '$medium',
        padding: '0 $medium',
        fontSize: '$large',

        '& svg': {
          marginLeft: '-.2rem'
        }
      }
    },
    fullWidth: {
      true: {
        width: '100%'
      }
    },
    selected: {
      true: {}
    },
    outlined: {
      true: {}
    }
  },

  compoundVariants: [
    {
      selected: true,
      color: 'grey',
      css: {
        '&, &:hover, &:focus': {
          background: '$dark-800',
          color: '$light-1000'
        }
      }
    },

    // TODO: add selected states for other intents
    // https://vouch.atlassian.net/browse/VCH-2518

    {
      color: 'light',
      outlined: true,
      css: {
        border: '$borderWidths$thin solid $dark-80',

        '&:hover, &:focus': {
          borderColor: '$s-brand-200'
        },

        '&[data-disabled=true]': {
          '&&': {
            '&, &:hover, &:focus': {
              borderColor: '$dark-80',
              background: '$light-1000',
              color: '$dark-200'
            }
          }
        }
      }
    },
    {
      color: 'white-purple',
      outlined: true,
      css: {
        border: '$borderWidths$thin solid $dark-80',

        '&:hover, &:focus': {
          borderColor: '$purple-700'
        },

        '&[data-disabled=true]': {
          '&&': {
            '&, &:hover, &:focus': {
              borderColor: '$dark-80',
              background: '$light-1000',
              color: '$dark-200'
            }
          }
        }
      }
    }
  ],

  defaultVariants: {
    color: 'grey',
    size: 'medium'
  }
});

const Label = styled('span', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$$buttonLabelSpacing',
  color: 'inherit',

  '& > span': {
    display: 'flex'
  },

  variants: {
    hidden: {
      true: {
        opacity: 0,
        pointerEvents: 'none'
      }
    }
  }
});

const Loading = styled('span', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

type ButtonProps = Omit<
  ComponentProps<typeof Container> & Partial<ComponentProps<typeof Link>>,
  'onClick' | 'color'
> & {
  loading?: boolean;
  onClick?: (e: MouseEvent) => any | Promise<any>;
  icon?: ComponentProps<typeof Icon>['name'];
  color: ComponentProps<typeof Container>['color'];
  tooltip?: ComponentProps<typeof Tooltip>['content'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  analyticsId?: string;
};

const Button = forwardRef(function Button(
  {
    disabled,
    loading: loadingProp,
    icon,
    onClick,
    children,
    tooltip,
    tooltipPlacement,
    prefetch,
    analyticsId,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const event = useAnalyticsEvent();

  const [isLoading, setLoading] = useState(false);
  const actuallyLoading = loadingProp !== undefined ? loadingProp : isLoading;

  const handleClick = useCallback(
    async (e: MouseEvent) => {
      if (actuallyLoading || disabled) {
        // Don't trigger callback when button is currently in loading state or disabled
        return;
      }
      try {
        if (analyticsId) {
          event('button_clicked', {
            type: analyticsId,
            // TODO: ideal future event attributes
            button_id: analyticsId
          });
        }
        const res = onClick?.(e);
        if (res?.then) {
          setLoading(true);
          await res;
        }
      } finally {
        setLoading(false);
      }
    },
    [event, onClick, analyticsId, actuallyLoading, disabled]
  );

  if ('href' in props && props.href) {
    const { href, ...rest } = props;
    return (
      <Tooltip content={tooltip} placement={tooltipPlacement}>
        {({ _, ...tooltipProps }) => (
          <Container
            as={Link as any}
            href={href}
            data-analytics-id={analyticsId}
            {...tooltipProps}
            {...rest}
            onClick={handleClick}
            data-disabled={disabled}
            data-outlined={!!props.outlined}
            ref={tooltipProps.ref ? mergeRefs([ref, tooltipProps.ref]) : ref}
          >
            <Label hidden={actuallyLoading}>
              {icon && <Icon name={icon} />}
              {children && <span>{children}</span>}
            </Label>

            {actuallyLoading && (
              <Loading>
                <LoadingSpinner />
              </Loading>
            )}
          </Container>
        )}
      </Tooltip>
    );
  }

  return (
    <Tooltip content={tooltip} placement={tooltipPlacement}>
      {({ _, ...tooltipProps }) => {
        return (
          <Container
            data-analytics-id={analyticsId}
            onClick={(e) => {
              // HACK: If the content of the button changes after the tooltip is shown, then it may not hide unless
              // interacted with directly. E.g. if the `icon` prop of an `IconBotton` changed with state external to
              // the tooltip, it would get stuck on iOS Safari - https://vouch.atlassian.net/browse/VCH-2513
              tooltipProps.onBlur?.(e);
              return handleClick(e);
            }}
            type={props.form ? 'submit' : 'button'}
            // NOTE: we want to disable the button when it's loading to prevent multiple form submissions when the user presses
            // the enter key, but for those loading states we don't want to use the disabled styles, so we're using a custom attribute
            disabled={disabled || actuallyLoading}
            data-disabled={disabled}
            data-outlined={!!props.outlined}
            {...props}
            {...tooltipProps}
            ref={tooltipProps.ref ? mergeRefs([tooltipProps.ref, ref]) : ref}
          >
            <Label hidden={actuallyLoading}>
              {icon && <Icon name={icon} />}
              {children && <span>{children}</span>}
            </Label>

            {actuallyLoading && (
              <Loading>
                <LoadingSpinner />
              </Loading>
            )}
          </Container>
        );
      }}
    </Tooltip>
  );
});

export { Button };
