import { forwardRef } from 'react';

import { Link } from '~/utils/routing/Link';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';

const Container = styled('div', {
  $$cardPadding: '$space$large',

  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  margin: 0,
  padding: '$$cardPadding',
  width: '100%',
  border: 'none',
  borderRadius: '$medium',
  flexShrink: 0,
  zIndex: 2,
  position: 'relative',
  minWidth: 0,
  fontWeight: '$regular',
  color: 'inherit',

  // Hiding overflow makes scrollbars respect border radius
  // but might have unintended side effects, keep an eye on this
  overflow: 'hidden',

  '&:hover, &:focus': {
    zIndex: 3,
    color: 'inherit'
  },

  variants: {
    interactive: {
      true: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        textAlign: 'inherit'
      }
    },
    color: {
      white: {
        background: '$light-1000'
      },
      grey: {
        background: '$dark-80'
      },
      teal: {
        background: '$teal-100'
      },
      blue: {
        background: '$blue-600'
      },
      'brand-light': {
        background: '$s-brand-100'
      },
      'purple-light': {
        background: '$purple-100',
        color: '$purple-900'
      },
      transparent: {
        background: 'transparent'
      }
    }
  },

  compoundVariants: [
    {
      interactive: true,
      color: 'white',
      css: {
        '&:hover, &:focus': {
          boxShadow: '$medium'
        }
      }
    },
    {
      interactive: true,
      color: 'grey',
      css: {
        '&:hover, &:focus': {
          background: '$dark-200'
        }
      }
    },
    {
      interactive: true,
      color: 'purple-light',
      css: {
        '&:hover, &:focus': {
          background: '$purple-100'
        }
      }
    }
  ],

  defaultVariants: {
    color: 'white'
  }
});

type CardProps = Omit<ComponentProps<typeof Container>, 'interactive' | 'ref'> & {
  href?: ComponentProps<typeof Link>['href'];
  target?: ComponentProps<typeof Link>['target'];
};

const Card = forwardRef(function Card({ children, color, ...props }: CardProps, ref: ForwardedRef<HTMLDivElement>) {
  if ('href' in props && props.href) {
    const { href, ...rest } = props;
    return (
      <Container ref={ref} href={href} as={Link as any} color={color} interactive {...rest}>
        {children}
      </Container>
    );
  }

  if ('onClick' in props && props.onClick) {
    return (
      <Container ref={ref} as={'button' as any} type="button" color={color} interactive {...props}>
        {children}
      </Container>
    );
  }

  return (
    <Container ref={ref} color={color} {...props}>
      {children}
    </Container>
  );
});

export { Card };
