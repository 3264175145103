import { styled } from '~/utils/styling';

const ScrollBox = styled('div', {
  width: '100%',
  overflow: 'auto',
  padding: '$medium',
  borderRadius: '$medium',
  backgroundColor: '$light-1000',

  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },

  '&::-webkit-scrollbar-thumb': {
    background: '$teal-700',
    borderRadius: '$tiny'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '$dark-600'
  },
  '&::-webkit-scrollbar-track': {
    background: '$dark-100',
    borderRadius: '4px'
  }
});

export { ScrollBox };
