import { useCallback, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import mergeRefs from 'react-merge-refs';

import { FieldLabel } from '~/components/form/FieldLabel';
import { Icon } from '~/components/icon';
import { styled } from '~/utils/styling';

import type { ChangeEvent, ComponentPropsWithoutRef, ReactNode, ComponentProps, ComponentType, Ref } from 'react';
import type { FieldError, ErrorOption } from 'react-hook-form';
import type { Tooltip } from '~/components/tooltip';
import type { PlanSticker } from '~/features/billing/components/PlanSticker';

import { useFormState } from './FormContext';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$wee',
  overflow: 'hidden',
  margin: '-.4rem',
  padding: '.4rem',
  width: 'calc(100% + .8rem)',

  variants: {
    inline: {
      true: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '$small'
      }
    }
  }
});

const ErrorMessage = styled('div', {
  fontSize: '$small',
  display: 'flex',
  flexDirection: 'row',

  '& svg': {
    height: '1.2rem',
    width: 'auto',
    marginRight: '$wee',
    color: '$s-danger-500'
  }
});

type FakeEvent = {
  target: {
    value: any;
  };
};

type FieldPassthroughProps = {
  ref?: Ref<any>;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: ChangeEvent) => void;
  id?: string;
  name?: string;
  getValue?: () => any;
  setValue?: (value: any, args?: any) => void;
  error?: FieldError;
  setError?: (error: ErrorOption, options?: any) => void;
  clearError?: () => void;
};

type ValidateFn<Value = any> = (value: Value) => boolean | string;
type Validate<Value = any> = ValidateFn<Value> | { [key: string]: ValidateFn<Value> };

type FieldProps<InputComponent extends ComponentType<FieldPassthroughProps>> = Omit<
  ComponentProps<typeof Container>,
  'onChange'
> & {
  name: string;
  label?: ReactNode;
  labelAction?: ReactNode;
  description?: ReactNode;
  Input: InputComponent;
  // The input props type is inferred from the passed in input components, removing the
  // props the field component passes through, but allowing for optional `onChange`
  // and `onBlur` props, which will get merged with the form event handlers
  inputProps?: Omit<ComponentPropsWithoutRef<InputComponent>, keyof FieldPassthroughProps> & {
    onChange?: FieldPassthroughProps['onChange'];
    onBlur?: FieldPassthroughProps['onBlur'];
    ref?: any;
  };
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  validate?: Validate;
  valueAsNumber?: boolean;
  setValueAs?: (value: any) => any;
  helpTooltip?: ComponentProps<typeof Tooltip>['content'];
  showOptional?: boolean;
  plan?: ComponentProps<typeof PlanSticker>['type'];
  onChange?: (e: FakeEvent) => void;
};

function Field<InputComponent extends ComponentType<FieldPassthroughProps>>({
  name,
  label,
  labelAction,
  description,
  Input,
  inputProps,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  helpTooltip,
  inline,
  showOptional,
  plan,
  onChange,
  ...props
}: FieldProps<InputComponent>) {
  const { setValue: setFieldValue, setError: setFieldError, clearErrors, control } = useFormState<any>();

  const setValue = useCallback(
    (value: any, args: any = {}) => {
      onChange?.({
        target: {
          value
        }
      });
      setFieldValue(name, value, { shouldDirty: true, shouldTouch: true, ...args });
    },
    [name, onChange, setFieldValue]
  );

  const setError = useCallback(
    (error: Parameters<typeof setFieldError>[1], options: Parameters<typeof setFieldError>[2]) =>
      setFieldError(name, error, options),
    [name, setFieldError]
  );

  const clearError = useCallback(() => clearErrors(name), [clearErrors, name]);

  const InputComponent = Input as ComponentType<FieldPassthroughProps>;

  // We want to take advantage of native browser input validation (e.g. for input types like `email`, `url`
  // etc.), but we want to be able to control when that validation triggers + make sure the UI behaves the same
  // for custom and native validations and errors, so we disable native validation on the form element and
  // trigger it manually here if we have a valid ref
  const inputRef = useRef<any>();
  const mergeValidate = useMemo(
    () => ({
      native: () => {
        // https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation#validating_forms_using_javascript
        return inputRef.current?.checkValidity
          ? inputRef.current.checkValidity() || inputRef.current.validationMessage
          : true;
      },
      ...(typeof validate === 'function' ? { other: validate } : { ...validate })
    }),
    [validate]
  );

  return (
    <Container inline={inline} data-field-name={name} {...props}>
      {(label || description) && (
        <FieldLabel
          label={label}
          description={description}
          inline={inline}
          name={name}
          required={required}
          showOptional={showOptional}
          helpTooltip={helpTooltip}
          plan={plan}
          labelAction={labelAction}
        />
      )}

      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <>
            <InputComponent
              {...field}
              ref={mergeRefs([inputRef, field.ref])}
              getValue={() => field.value}
              setValue={setValue}
              id={name}
              error={fieldState.error}
              setError={setError}
              clearError={clearError}
              {...inputProps}
            />
            {fieldState.error && (
              <ErrorMessage>
                <Icon name="error" />
                <span>
                  {fieldState.error.message
                    ? fieldState.error.message
                    : fieldState.error.type === 'required'
                    ? 'This field is required'
                    : 'Validation failed.'}
                </span>
              </ErrorMessage>
            )}
          </>
        )}
        control={control}
        rules={{
          required,
          min,
          max,
          minLength,
          maxLength,
          pattern,
          validate: mergeValidate
        }}
      />
    </Container>
  );
}

export { Field };
export type { FieldPassthroughProps, Validate };
