import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareSlackModal = lazy(() => import('~/features/share/modals/ShareSlackModal'));

function useShareSlack() {
  const { open } = useModal(ShareSlackModal);
  return open;
}

export { useShareSlack };
