import { createContext, useContext, useState, useCallback } from 'react';

import { styled } from '~/utils/styling';

import type { recruiterTheme } from './theme';
import type { ReactNode } from 'react';

import { theme as defaultTheme } from './theme';

type ThemeClass = typeof defaultTheme | typeof recruiterTheme;

interface ThemeContextValue {
  currentTheme: ThemeClass;
  setTheme: (theme: ThemeClass) => void;
}

const ThemeWrapper = styled('div', {
  minHeight: '100%',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 'auto',

  '@bp-medium': {
    height: '100%',
    overflow: 'hidden'
  }
});

const noop = () => {
  // default placeholder
};

const ThemeContext = createContext<ThemeContextValue>({
  currentTheme: defaultTheme,
  setTheme: noop
});

const ThemeProvider = ({
  children,
  initialTheme = defaultTheme
}: {
  children: ReactNode;
  initialTheme?: ThemeClass;
}) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeClass>(initialTheme);

  const setTheme = useCallback((theme: ThemeClass) => {
    setCurrentTheme(theme);
  }, []);

  return (
    <ThemeContext.Provider value={{ currentTheme, setTheme }}>
      <ThemeWrapper className={currentTheme}>{children}</ThemeWrapper>
    </ThemeContext.Provider>
  );
};

const useTheme = (): ThemeContextValue => useContext(ThemeContext);

export { ThemeProvider, useTheme };
export type { ThemeClass, ThemeContextValue };
