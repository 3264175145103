import { useMemo } from 'react';

import { useShareEmailEmbed } from '~/features/share/actions/useShareEmailEmbed';
import { useShareEmbedCode } from '~/features/share/actions/useShareEmbedCode';
import { useShareLinkedin } from '~/features/share/actions/useShareLinkedin';
import { useShareQRCode } from '~/features/share/actions/useShareQRCode';
import { useShareSlack } from '~/features/share/actions/useShareSlack';

function useShareActions() {
  const shareQRCode = useShareQRCode();
  const shareEmbedCode = useShareEmbedCode();
  const shareEmailEmbed = useShareEmailEmbed();
  const shareLinkedin = useShareLinkedin();
  const shareSlack = useShareSlack();

  return useMemo(
    () => ({
      shareQRCode,
      shareEmbedCode,
      shareEmailEmbed,
      shareLinkedin,
      shareSlack
    }),
    [shareQRCode, shareEmbedCode, shareEmailEmbed, shareLinkedin, shareSlack]
  );
}

export { useShareActions };
