import { forwardRef } from 'react';

import { Flex } from '~/components/box';
import { Icon } from '~/components/icon';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef, ReactNode } from 'react';

import { Button } from './Button';

const Container = styled(Button, {
  flexShrink: 0,
  aspectRatio: 1,

  '&&': {
    padding: 0,

    '& svg': {
      marginRight: 0,
      marginLeft: 0,
      display: 'flex'
    }
  },

  variants: {
    round: {
      true: {
        borderRadius: '50%'
      }
    }
  },

  defaultVariants: {
    round: true
  }
});

type IconButtonProps = Omit<ComponentProps<typeof Container>, 'children' | 'color'> & {
  icon: ComponentProps<typeof Icon>['name'];
  color: ComponentProps<typeof Container>['color'];
  children?: ReactNode;
};

const IconButton = forwardRef(function IconButton(
  { icon, children, ...props }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Container ref={ref} {...props}>
      <Flex direction="column" justifyContent="center" alignItems="center">
        <div>
          <Icon name={icon} />
        </div>
        <div>{children}</div>
      </Flex>
    </Container>
  );
});

export { IconButton };
