import { lazy } from 'react';

import { useModal } from '~/components/modal';

const InviteRecruitersModal = lazy(() => import('~/features/team/modals/InviteRecruitersModal'));

function useInviteRecruiters() {
  const { open } = useModal(InviteRecruitersModal);
  return (props: Parameters<typeof open>[0] = {}) => {
    open(props);
  };
}

export { useInviteRecruiters };
